import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Upload, message } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const IMAGE_API_BASE_URL = 'https://api.ridgetechcorp.com/api'; // Replace with your API URL
const CASESTUDY_API_BASE_URL = 'https://api.ridgetechcorp.com/api/case-studies'; // Replace with your API URL

const CaseStudy = () =>
{
    const [caseStudies, setCaseStudies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [editingCaseStudy, setEditingCaseStudy] = useState(null);

    // Fetch case studies
    const fetchCaseStudies = async () =>
    {
        try
        {
            setLoading(true);
            const { data } = await axios.get(CASESTUDY_API_BASE_URL);
            setCaseStudies(data);
            setLoading(false);
        } catch (error)
        {
            setLoading(false);
            message.error('Failed to fetch case studies.');
        }
    };

    useEffect(() =>
    {
        fetchCaseStudies();
    }, []);

    // Handle Add/Edit Submission
    const handleSubmit = async (values) =>
    {
        try
        {
            const payload = { ...values };
            console.log({ payload })
            const formdata = {
                ...payload,
                image: payload?.image.fileList[0].response.url || '',
                challenge_images: payload?.challenge_images.fileList.map((fileDetails) =>
                {
                    return fileDetails.response.url || ''
                }),
                impact_box_images: payload?.impact_box_images.fileList.map((fileDetails) =>
                {
                    return fileDetails.response.url || ''
                }),
                brief_images: payload?.brief_images.fileList.map((fileDetails) =>
                {
                    return fileDetails.response.url || ''
                }),
                client_details: {
                    client_bio: payload?.client_bio,
                    client_designation: payload?.client_designation,
                    client_name: payload?.client_name,
                    client_image: payload?.client_image.fileList[0].response.url || ''
                }
            }
            delete formdata.client_image;
            delete formdata.image
            console.log({ payload, formdata })

            if (editingCaseStudy)
            {
                // Update
                await axios.put(`${ CASESTUDY_API_BASE_URL }/${ editingCaseStudy._id }`, formdata);
                message.success('Case study updated successfully.');
            } else
            {
                // Create
                await axios.post(CASESTUDY_API_BASE_URL, formdata);
                message.success('Case study added successfully.');
            }

            fetchCaseStudies();
            setIsModalOpen(false);
            form.resetFields();
        } catch (error)
        {
            message.error('Failed to save case study.');
        }
    };

    // Handle Delete
    const handleDelete = async (id) =>
    {
        try
        {
            await axios.delete(`${ CASESTUDY_API_BASE_URL }/${ id }`);
            message.success('Case study deleted successfully.');
            fetchCaseStudies();
        } catch (error)
        {
            message.error('Failed to delete case study.');
        }
    };

    // Image Upload
    const uploadImage = async (file) =>
    {
        try
        {
            const formData = new FormData();
            formData.append('image', file);

            const response = await axios.post(`${ IMAGE_API_BASE_URL }/upload`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log(response)
            return response.data.imageUrl; // Assuming the API returns the image URL in `url`
        } catch (error)
        {
            message.error('Image upload failed.');
            throw error;
        }
    };

    // Columns for AntD Table
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Brief',
            dataIndex: 'brief',
            key: 'brief',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() =>
                        {
                            setEditingCaseStudy(record);
                            form.setFieldsValue(record);
                            setIsModalOpen(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button type="link" danger onClick={() => handleDelete(record._id)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-bold">Case Studies</h1>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() =>
                    {
                        setEditingCaseStudy(null);
                        form.resetFields();
                        setIsModalOpen(true);
                    }}
                >
                    Add Case Study
                </Button>
            </div>

            <Table
                dataSource={caseStudies}
                columns={columns}
                rowKey="_id"
                loading={loading}
                bordered
            />

            <Modal
                title={editingCaseStudy ? 'Edit Case Study' : 'Add Case Study'}
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                {console.log(editingCaseStudy?.client_details)}
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        ...editingCaseStudy,
                        client_details: editingCaseStudy?.client_details,  // Ensure client details are present
                        sidebar_Details: editingCaseStudy?.sidebar_Details  // Ensure sidebar details are present
                    }}
                >
                    <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please enter a title' }]}>
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item name="metaTitle" label="Meta Title">
                        <Input placeholder="Meta Title" />
                    </Form.Item>
                    <Form.Item name="metaDescription" label="Meta Description">
                        <Input placeholder="Meta Description" />
                    </Form.Item>
                    <Form.Item name="image" label="Main Image">
                        <Upload
                            name="image"
                            listType="picture-card"
                            customRequest={async ({ file, onSuccess, onError }) =>
                            {
                                try
                                {
                                    const url = await uploadImage(file); // Upload the image
                                    console.log({ url })
                                    file.response = { url };
                                    form.setFieldsValue({ image: url }); // Update the form
                                    onSuccess({ url }); // Notify Upload component of success
                                } catch (error)
                                {
                                    onError(error); // Notify Upload component of failure
                                }
                            }}

                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="brief" label="Brief Title">
                        <Input.TextArea placeholder="Breif Title" rows={4} />
                    </Form.Item>
                    <Form.Item name="brief_text" label="Brief Text">
                        <Input.TextArea placeholder="Breif Text" rows={4} />
                    </Form.Item>
                    <Form.Item name="brief_images" label="Brief Images">
                        <Upload
                            name="brief_images"
                            listType="picture-card"
                            beforeUpload={async (file) =>
                            {
                                const url = await uploadImage(file);  // Upload the image and get the URL
                                file.response = { url };
                                const currentImages = form.getFieldValue('brief_images') || [];  // Get existing images (if any)
                                form.setFieldsValue({
                                    brief_images: [...currentImages, url]  // Add new image URL to the array
                                });
                                return false;  // Prevent automatic upload
                            }}

                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="challenge" label="Challenge" rules={[{ required: true, message: 'Please enter a challenge' }]}>
                        <Input.TextArea placeholder="Challenge" rows={4} />
                    </Form.Item>
                    <Form.Item name="challenge_text" label="Challenge Text" rules={[{ required: true, message: 'Please enter challenge text' }]}>
                        <Input.TextArea placeholder="Challenge Text" rows={4} />
                    </Form.Item>
                    <Form.Item name="challenge_images" label="Challenge Images">
                        <Upload
                            name="challenge_images"
                            listType="picture-card"
                            beforeUpload={async (file) =>
                            {
                                const url = await uploadImage(file);  // Upload the image and get the URL
                                const currentImages = form.getFieldValue('challenge_images') || [];  // Get existing images (if any)
                                file.response = { url };
                                form.setFieldsValue({
                                    challenge_images: [...currentImages, url]  // Add new image URL to the array
                                });
                                return false;  // Prevent automatic upload
                            }}

                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item name="solution" label="Solution">
                        <Input.TextArea placeholder="Solution" rows={4} />
                    </Form.Item>
                    <Form.Item name="solution_text" label="Solution Text">
                        <Input.TextArea placeholder="Solution Text" rows={4} />
                    </Form.Item>
                    <Form.Item label="Solution Box">
                        <Form.List
                            name="solution_box"
                            initialValue={editingCaseStudy ? editingCaseStudy.solution_box || [] : []}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <div
                                            key={key}
                                            style={{
                                                marginBottom: '16px',
                                                border: '1px solid #f0f0f0',
                                                padding: '16px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'title']}
                                                fieldKey={[fieldKey, 'title']}
                                                label="Title"
                                                rules={[{ required: true, message: 'Please enter a title' }]}
                                            >
                                                <Input placeholder="Title" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'text']}
                                                fieldKey={[fieldKey, 'text']}
                                                label="Text"
                                                rules={[{ required: true, message: 'Please enter text' }]}
                                            >
                                                <Input placeholder="Text" />
                                            </Form.Item>
                                            <Button
                                                type="dashed"
                                                danger
                                                onClick={() => remove(name)}
                                                style={{ marginTop: '8px' }}
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        style={{ marginTop: '16px' }}
                                    >
                                        Add Solution Box
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item name="impact" label="Impact">
                        <Input.TextArea placeholder="Impact" rows={4} />
                    </Form.Item>
                    <Form.Item name="impact_text" label="Impact Text">
                        <Input.TextArea placeholder="Impact Text" rows={4} />
                    </Form.Item>
                    <Form.Item label="Impact Text Box">
                        <Form.List
                            name="impact_text_box"
                            initialValue={editingCaseStudy ? editingCaseStudy.impact_text_box || [] : []}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <div
                                            key={key}
                                            style={{
                                                marginBottom: '16px',
                                                border: '1px solid #f0f0f0',
                                                padding: '16px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'number']}
                                                fieldKey={[fieldKey, 'number']}
                                                label="Number"
                                                rules={[{ required: true, message: 'Please enter a number' }]}
                                            >
                                                <Input placeholder="Number" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'heading']}
                                                fieldKey={[fieldKey, 'heading']}
                                                label="Heading"
                                                rules={[{ required: true, message: 'Please enter a heading' }]}
                                            >
                                                <Input placeholder="Heading" />
                                            </Form.Item>
                                            <Button
                                                type="dashed"
                                                danger
                                                onClick={() => remove(name)}
                                                style={{ marginTop: '8px' }}
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        style={{ marginTop: '16px' }}
                                    >
                                        Add Impact Text Box
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>



                    <Form.Item name="impact_box_images" label="Impact Box Images">
                        <Upload
                            name="impact_box_images"
                            listType="picture-card"
                            beforeUpload={async (file) =>
                            {
                                const url = await uploadImage(file);  // Upload the image and get the URL
                                file.response = { url };
                                const currentImages = form.getFieldValue('impact_box_images') || [];  // Get existing images (if any)
                                form.setFieldsValue({
                                    impact_box_images: [...currentImages, url]  // Append new image URL to the array
                                });
                                return false;  // Prevent automatic upload handling
                            }}

                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>





                    <Form.Item name="client_details" label="Client Details">
                        <Form.Item name="client_name" label="Client Name">
                            <Input placeholder="Client Name" />
                        </Form.Item>
                        <Form.Item name="client_bio" label="Client Bio">
                            <Input.TextArea placeholder="Client Bio" rows={4} />
                        </Form.Item>
                        <Form.Item name="client_designation" label="Client Designation">
                            <Input placeholder="Client Designation" />
                        </Form.Item>
                        <Form.Item name="client_image" label="Client Image">
                            <Upload
                                name="client_image"
                                listType="picture-card"
                                beforeUpload={async (file) =>
                                {
                                    const url = await uploadImage(file);  // Upload the image and get the URL
                                    file.response = { url };
                                    form.setFieldsValue({ client_image: url });  // Set the image URL in the form
                                    return false;  // Prevent automatic upload handling
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label="Sidebar Details">
                        <Form.Item name={['sidebar_Details', 'client']} label="Client">
                            <Input placeholder="Client" />
                        </Form.Item>

                        <Form.List name={['sidebar_Details', 'services']}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey }) => (
                                        <div key={key} style={{ marginBottom: '8px', border: '1px solid #d9d9d9', padding: '8px', borderRadius: '4px' }}>
                                            <Form.Item
                                                name={[name, 'name']}
                                                fieldKey={[fieldKey, 'name']}
                                                label="Service Name"
                                                rules={[{ required: true, message: 'Please enter the service name' }]}
                                            >
                                                <Input placeholder="Service Name" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[name, 'pagelink']}
                                                fieldKey={[fieldKey, 'pagelink']}
                                                label="Service Page Link"
                                                rules={[{ required: true, message: 'Please enter the page link' }]}
                                            >
                                                <Input placeholder="Page Link" />
                                            </Form.Item>
                                            <Button type="dashed" onClick={() => remove(name)} block>
                                                Remove Service
                                            </Button>
                                        </div>
                                    ))}
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Service
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>

                    <Form.Item name="conclusion" label="Conclusion">
                        <Input.TextArea placeholder="Conclusion" rows={4} />
                    </Form.Item>
                    <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select a status' }]}>
                        <Input placeholder="Status (active/inactive)" />
                    </Form.Item>
                    <div className="flex justify-end">
                        <Button type="primary" htmlType="submit">
                            {editingCaseStudy ? 'Update' : 'Submit'}
                        </Button>
                    </div>
                </Form>

            </Modal>
        </div >
    );
};

export default CaseStudy;

// pages/ResourceManagement.js
import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Form, Input, Upload, message, Switch, Select, InputNumber } from 'antd';
import { getLocationList } from '../../../api/locationApi';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import
{
    getResourceList,
    createResource,
    updateResource,
    deleteResource,
    toggleResourceStatus,
    uploadResourceIcon,
    deleteResourceIcon
} from '../../../api/resourceApi';
import { getParentResourceList } from '../../../api/parentResApi';

const { Option } = Select;

const ResourceManagement = () =>
{
    const [resources, setResources] = useState([]);
    const [resourcesParent, setResourcesParent] = useState([]);
    const [location, setLocation] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [iconUrl, setIconUrl] = useState(null);
    const [editingResource, setEditingResource] = useState(null);

    useEffect(() =>
    {
        fetchResources();
        fetchLocation();
        fetchResourcesParent();
    }, []);

    const fetchResources = async () =>
    {
        const data = await getResourceList();
        setResources(data);
    };

    const fetchResourcesParent = async () =>
    {
        const data = await getParentResourceList();
        setResourcesParent(data);
    };

    const fetchLocation = async () =>
    {
        const data = await getLocationList();
        setLocation(data);
    };

    const handleSubmit = async (values) =>
    {
        const resourceData = { ...values, icon: iconUrl };

        if (editingResource)
        {
            await updateResource(editingResource._id, resourceData);
        } else
        {
            await createResource(resourceData);
        }

        fetchResources();
        setIsModalVisible(false);
        form.resetFields();
        setIconUrl(null);
        setEditingResource(null);
    };

    const handleIconUpload = async (options) =>
    {
        const { file, onSuccess, onError } = options;
        const formData = new FormData();
        formData.append('image', file);

        try
        {
            const response = await uploadResourceIcon(formData);
            setIconUrl(response.imageUrl);
            onSuccess("Upload successful");
            message.success('Icon uploaded successfully');
        } catch (error)
        {
            onError(error);
            message.error('Error uploading icon');
        }
    };

    const handleDeleteResource = async (id, iconId) =>
    {
        if (iconId) await deleteResourceIcon(iconId);
        await deleteResource(id);
        fetchResources();
    };

    const handleEdit = (resource) =>
    {
        setEditingResource(resource);
        setIconUrl(resource.icon);
        form.setFieldsValue({
            ...resource,
            resource: resource.resource?._id,
            locations: resource.locations?.map(location => location._id)
        });
        setIsModalVisible(true);
    };

    return (
        <div>
            <Button type="primary" onClick={() => { setEditingResource(null); setIsModalVisible(true); }} icon={<PlusOutlined />}>
                Add Resource
            </Button>
            <Table
                dataSource={resources}
                rowKey="_id"
                columns={[
                    { title: 'Name', dataIndex: 'name', key: 'name' },
                    {
                        title: 'Resource For',
                        dataIndex: ['resource', 'name'],
                        key: 'resource',
                        render: (resource) => (resource ? resource : 'N/A')
                    },
                    {
                        title: 'Locations',
                        dataIndex: 'locations',
                        key: 'locations',
                        render: (locations) => locations.map(loc => loc.shortCode).join(', ')
                    },
                    {
                        title: 'Icon',
                        dataIndex: 'icon',
                        key: 'icon',
                        render: (icon) => (icon ? <img src={icon} alt="Icon" style={{ width: '50px' }} /> : 'No Icon'),
                    },
                    {
                        title: 'Active',
                        dataIndex: 'isActive',
                        key: 'isActive',
                        render: (text, record) => (
                            <Switch
                                checked={record.isActive}
                                onChange={() => toggleResourceStatus(record._id)}
                            />
                        ),
                    },
                    {
                        title: 'Actions',
                        key: 'actions',
                        render: (text, record) => (
                            <>
                                <Button onClick={() => handleEdit(record)}>Edit</Button>
                                <Button danger onClick={() => handleDeleteResource(record._id, record.iconId)}>
                                    Delete
                                </Button>
                            </>
                        ),
                    },
                ]}
            />
            <Modal
                title={editingResource ? 'Edit Resource' : 'Add Resource'}
                visible={isModalVisible}
                maskClosable={false}
                onCancel={() => { setIsModalVisible(false); form.resetFields(); setEditingResource(null); setIconUrl(null); }}
                onOk={() => form.submit()}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item name="name" placeholder="e.g., Angular, React" label="Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="metaTitle" placeholder="Enter meta title" label="Meta Title " rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="metaDescription" placeholder="Enter meta description" label="Meta Description" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="callingName" placeholder="e.g., Angular, React" label="Calling Name" rules={[{ required: true }]}>
                        <Input placeholder='Enter calling name' />
                    </Form.Item>
                    <Form.Item name="resource" label="Resource For">
                        <Select placeholder="Select Resource">
                            {resourcesParent.map((res) => (
                                <Option key={res._id} value={res._id}>
                                    {res.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="locations" label="Locations">
                        <Select mode="multiple" placeholder="Select locations">
                            {location.map((loc) => (
                                <Option key={loc._id} value={loc._id}>
                                    {loc.shortCode}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Icon">
                        <Upload
                            name="icon"
                            showUploadList={false}
                            customRequest={handleIconUpload}
                        >
                            <Button icon={<UploadOutlined />}>Upload Icon</Button>
                        </Upload>
                        {iconUrl && <img src={iconUrl} alt="Icon Preview" style={{ width: '50px', marginTop: '10px' }} />}
                    </Form.Item>
                    <Form.Item name="isActive" label="Active" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item name={'smallDescription'} label="Small Description - 40 words">
                        <Input.TextArea rows={2} />
                    </Form.Item>

                    <h3>Details</h3>
                    <Form.List name="details">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} style={{ marginBottom: 10 }}>
                                        <Form.Item {...restField} name={[name, 'number']} label="Detail Number">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item {...restField} name={[name, 'text']} label="Detail Text -3">
                                            <Input />
                                        </Form.Item>
                                        <Button type="dashed" onClick={() => remove(name)}>Remove Detail</Button>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()}>Add Detail</Button>
                            </>
                        )}
                    </Form.List>

                    <h3>Quality Services</h3>
                    <Form.List name="qualityServices">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} style={{ marginBottom: 10 }}>
                                        <Form.Item {...restField} name={[name, 'heading']} label="Quality Service Heading" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item {...restField} name={[name, 'description']} label="Quality Service Description -25 words" rules={[{ required: true }]}>
                                            <Input.TextArea rows={2} />
                                        </Form.Item>
                                        <Button type="dashed" onClick={() => remove(name)}>Remove Quality Service</Button>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()}>Add Quality Service</Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </div>
    );
};

export default ResourceManagement;

import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Upload, Checkbox } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@mui/icons-material';

const EditJob = ({ job, onSuccess, onClose }) =>
{
    const [form] = Form.useForm();
    const [postOnLinkedIn, setPostOnLinkedIn] = useState(false);
    const [linkedinImage, setLinkedinImage] = useState(null);

    useEffect(() =>
    {
        if (job)
        {
            form.setFieldsValue(job);
        }
    }, [job]);

    const onFinish = async (values) =>
    {
        // Split keyResponsibilities and qualifications into arrays
        console.log(values.keyResponsibility)
        values.keyResponsibility = !Array.isArray(values.keyResponsibility) ? values.keyResponsibility?.split('\n').map(item => item.trim()).filter(item => item) : values.keyResponsibility; // Split by newline, trim, and filter out empty lines
        values.qualification = !Array.isArray(values.qualification) ? values.qualification?.split('\n').map(item => item.trim()).filter(item => item) : values.qualification; // Split by newline, trim, and filter out empty lines

        // New field: Split emails into an array
        console.log(values.emailNotifications.length)
        values.emailNotifications = values?.emailNotifications.length === 1 ? values?.emailNotifications : values?.emailNotifications.split('/n');
        // Add LinkedIn fields
        values.postOnLinkedIn = postOnLinkedIn;
        values.linkedinImage = linkedinImage?.imageUrl; // Include image URL if uploaded
        console.log(values)
        // return false

        await axios.put(`https://api.ridgetechcorp.com/api/jobs/${ job._id }`, values); // Adjust the API endpoint as needed
        form.resetFields();
        onSuccess();
    };

    if (!job) return null;
    const handleImageUpload = (info) =>
    {
        console.log(info)
        if (info.file.status === 'done')
        {
            // Assuming the backend returns the uploaded image URL in the response
            setLinkedinImage(info.file.response);
            message.success(`${ info.file.name } uploaded successfully`);
        } else if (info.file.status === 'error')
        {
            message.error(`${ info.file.name } upload failed.`);
        }
    };
    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="title" label="Job Title" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="experience" label="Experience" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="opening" label="Openings" rules={[{ required: true }]}>
                <Input type="number" />
            </Form.Item>
            <Form.Item name="jobOverview" label="Job Overview" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="keyResponsibility" label="Key Responsibilities" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="qualification" label="Qualifications" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="emailNotifications" label="Notification Emails" rules={[{ required: true }]}>
                <Input placeholder="Enter emails separated by commas" />
            </Form.Item>
            <Form.Item>
                <Checkbox checked={postOnLinkedIn} onChange={(e) => setPostOnLinkedIn(e.target.checked)}>
                    Post on LinkedIn
                </Checkbox>
            </Form.Item>

            {postOnLinkedIn && (
                <Form.Item name="linkedinImage" label="Upload LinkedIn Image">
                    <Upload
                        name="image"
                        action="https://api.ridgetechcorp.com/api/upload" // Replace with your image upload endpoint
                        onChange={handleImageUpload}
                        listType="picture"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>
            )}

            <Form.Item>
                <Button type="primary" htmlType="submit">Update Job</Button>
            </Form.Item>
        </Form>
    );
};

export default EditJob;

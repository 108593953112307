// AdminLayout.js
import React, { useState } from 'react';
import { Layout, Menu, Avatar, Button } from 'antd';
import { UserOutlined, DashboardOutlined, EditOutlined, SettingOutlined, AppstoreAddOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';

const { Header, Sider, Content } = Layout;

const AdminLayout = ({ setIsAuthenticated }) =>
{
    const [selectedTab, setSelectedTab] = useState('dashboard');
    const navigate = useNavigate();

    const handleMenuClick = (e) =>
    {
        setSelectedTab(e.key);
        if (e.key === 'blog-management')
        {
            navigate('/blog-list');
        } else if (e.key === 'user-management')
        {
            navigate('/user-management');
        } else if (e.key === 'role-management')
        {
            navigate('/role-management');
        }
        else if (e.key === 'tag-management')
        {
            navigate('/tag-management');
        }
        else if (e.key === 'image-gallery')
        {
            navigate('/image-gallery');
        }
        else if (e.key === 'job-posting')
        {
            navigate('/job-posting');
        }
        else if (e.key === 'resource-management')
        {
            navigate('/resource-management');
        } else if (e.key === 'location-management')
        {
            navigate('/location-management');
        }
        else if (e.key === 'parent-resource-management')
        {
            navigate('/parent-resource-management');
        }
        else if (e.key === 'query-table')
        {
            navigate('/query-table');
        }
        else if (e.key === 'service-page')
        {
            navigate('/service-page');
        }
        else if (e.key === 'technology')
        {
            navigate('/technology');
        }
        else if (e.key === 'service')
        {
            navigate('/service');
        }
        else if (e.key === 'faq')
        {
            navigate('/faq');
        }
        else if (e.key === 'case-study')
        {
            navigate('/case-study');
        }
    };

    const handleLogout = () =>
    {
        sessionStorage.removeItem('isAuthenticated'); // Clears session data
        setIsAuthenticated(false); // Updates authentication status
        navigate('/login'); // Redirects to login page
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header className="header">
                <div className="profile-section">
                    <Avatar size={40} icon={<UserOutlined />} />
                    <span className="profile-name"></span>
                    <Button type="primary" className="logout-button" onClick={handleLogout}>
                        Logout
                    </Button>
                </div>
            </Header>
            <Layout>
                <Sider className="sider">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['dashboard']}
                        style={{ height: '100%', borderRight: 0 }}
                        onClick={handleMenuClick}
                    >
                        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="service-page" icon={<DashboardOutlined />}>
                            Service Page
                        </Menu.Item>
                        <Menu.Item key="user-management" icon={<UserOutlined />}>
                            User Management
                        </Menu.Item>
                        <Menu.Item key="blog-management" icon={<EditOutlined />}>
                            Blog Management
                        </Menu.Item>
                        <Menu.Item key="job-posting" icon={<EditOutlined />}>
                            Job Posting
                        </Menu.Item>
                        <Menu.Item key="case-study" icon={<SettingOutlined />}>
                            Case Study
                        </Menu.Item>
                        <Menu.Item key="tag-management" icon={<SettingOutlined />}>
                            Tag Management
                        </Menu.Item>
                        <Menu.Item key="image-gallery" icon={<SettingOutlined />}>
                            Image Gallery
                        </Menu.Item>
                        <Menu.Item key="parent-resource-management" icon={<AppstoreAddOutlined />}>
                            Parent Resource Management
                        </Menu.Item>
                        <Menu.Item key="resource-management" icon={<AppstoreAddOutlined />}>
                            Resource Management
                        </Menu.Item>
                        <Menu.Item key="technology" icon={<AppstoreAddOutlined />}>
                            Technology
                        </Menu.Item>
                        <Menu.Item key="service" icon={<AppstoreAddOutlined />}>
                            Service
                        </Menu.Item>
                        <Menu.Item key="faq" icon={<AppstoreAddOutlined />}>
                            faq
                        </Menu.Item>
                        <Menu.Item key="location-management" icon={<EnvironmentOutlined />}>
                            Location Management
                        </Menu.Item>
                        <Menu.Item key="query-table" icon={<EnvironmentOutlined />}>
                            Query Table
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{ padding: '24px' }}>
                    <Content className="content">
                        <Outlet /> {/* This is where the child routes will be rendered */}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default AdminLayout;

import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Upload, message } from 'antd';
import axios from 'axios';
import { UploadOutlined } from '@ant-design/icons';

const AddJob = ({ onSuccess }) =>
{
    const [form] = Form.useForm();
    const [postOnLinkedIn, setPostOnLinkedIn] = useState(false);
    const [linkedinImage, setLinkedinImage] = useState(null);

    const onFinish = async (values) =>
    {
        try
        {
            // Split keyResponsibilities and qualifications into arrays
            values.keyResponsibility = values.keyResponsibility.split('\n').map(item => item.trim()).filter(item => item);
            values.qualification = values.qualification.split('\n').map(item => item.trim()).filter(item => item);

            // Split emails into an array
            values.emailNotifications = values.emailNotifications.split(',').map(item => item.trim()).filter(item => item);

            // Add LinkedIn fields
            values.postOnLinkedIn = postOnLinkedIn;
            values.linkedinImage = linkedinImage?.imageUrl; // Include image URL if uploaded

            // API call to create the job
            await axios.post('https://api.ridgetechcorp.com/api/jobs', values);

            message.success('Job added successfully!');
            form.resetFields();
            setLinkedinImage(null);
            setPostOnLinkedIn(false);
            onSuccess();
        } catch (error)
        {
            console.error('Error creating job:', error);
            message.error('Failed to add job. Please try again.');
        }
    };

    const handleImageUpload = (info) =>
    {
        if (info.file.status === 'done')
        {
            // Assuming the backend returns the uploaded image URL in the response
            setLinkedinImage(info.file.response);
            message.success(`${ info.file.name } uploaded successfully`);
        } else if (info.file.status === 'error')
        {
            message.error(`${ info.file.name } upload failed.`);
        }
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item name="title" label="Job Title" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="experience" label="Experience" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="opening" label="Openings" rules={[{ required: true }]}>
                <Input type="number" />
            </Form.Item>
            <Form.Item name="jobOverview" label="Job Overview" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="keyResponsibility" label="Key Responsibilities" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="qualification" label="Qualifications" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="emailNotifications" label="Notification Emails" rules={[{ required: true }]}>
                <Input placeholder="Enter emails separated by commas" />
            </Form.Item>

            {/* Checkbox for LinkedIn Posting */}
            <Form.Item>
                <Checkbox checked={postOnLinkedIn} onChange={(e) => setPostOnLinkedIn(e.target.checked)}>
                    Post on LinkedIn
                </Checkbox>
            </Form.Item>

            {/* LinkedIn Image Upload (visible only if LinkedIn posting is selected) */}
            {postOnLinkedIn && (
                <Form.Item name="linkedinImage" label="Upload LinkedIn Image">
                    <Upload
                        name="image"
                        action="https://api.ridgetechcorp.com/api/upload" // Replace with your image upload endpoint
                        onChange={handleImageUpload}
                        listType="picture"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>
            )}

            <Form.Item>
                <Button type="primary" htmlType="submit">Add Job</Button>
            </Form.Item>
        </Form>
    );
};

export default AddJob;
